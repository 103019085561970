import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const insuranceService = {
    listForms
}

function listForms({ loggedId, currentPage, perPage, dateStart, dateEnd, sortColumn, sortDirection, evento_id }) {
    let url = config.api + `/v1/admin/insurance/listForms?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&evento_id=${evento_id}`;
    url = config.system.applyPagination(url, currentPage, perPage);
  
    const options = {
      responseType: 'json',
    };
  
    return Vue.http.get(url, options)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error('Error downloading XLSX file or fetching data:', error);
        throw error;
      });
  }