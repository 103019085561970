import { render, staticRenderFns } from "./insurance.vue?vue&type=template&id=16967b32&scoped=true&"
import script from "./insurance.vue?vue&type=script&lang=js&"
export * from "./insurance.vue?vue&type=script&lang=js&"
import style0 from "./insurance.vue?vue&type=style&index=0&id=16967b32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16967b32",
  null
  
)

component.options.__file = "insurance.vue"
export default component.exports