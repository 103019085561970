<template>
  <div class="report-revenues" v-if="mayIsee">
      <b-container style="max-width: 92% !important">
          <b-row class="mb-3">
              <div>
                  <b-input-group>
                      <HotelDatePicker class="item-date-picker-revenue" format="DD-MM-YYYY"
                          @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm
                          range placeholder="Selecione o período"></HotelDatePicker>
                      <b-form-select
                        class="revenue-selects"
                        @change="selectionUpdated"
                        v-model="eventSelected"
                        :options="eventsToShow"
                      ></b-form-select>
                      <b-button @click="exportData" type="button" class="btn btn-success" variant="success" size="sm" v-if="grids.default.loaded && grids.default.items.length" style="margin-left: 5px;">
                          <span>Download <i class="fas fa-cloud"></i></span>
                      </b-button>
                  </b-input-group>
              </div>
          </b-row>
          <b-row>
              <span class="selectgroup-button selectgroup-button-icon" v-if="grids.default.loaded" style="cursor: auto">Total: {{ grids.default.total }}</span>
              <b-table ref="myTable" :no-local-sorting='true' striped="striped" outlined="outlined" class="fontSize tableClicked bg-white" small="small"
                  hover="hover" responsive show-empty empty-text="Não foram encontrados registros."
                  :per-page="grids.default.perPage" v-if="grids.default.loaded" :items="grids.default.items"
                  :fields="grids.default.fields" @sort-changed='handleSort'>
              </b-table>
              <b-pagination @change="pagto" size="sm"
                  v-if="grids.default.loaded && grids.default.total > grids.default.perPage"
                  :total-rows="grids.default.total" v-model="grids.default.currentPage" :per-page="grids.default.perPage">
              </b-pagination>
          </b-row>
      </b-container>
  </div>
</template>
<script>
import Vue from "vue";
import VueHead from "vue-head";
import HotelDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import { eventService } from "../../components/common/services/event";
import { insuranceService } from "../../components/common/services/insurance";
import DataExporter from "../../components/common/helpers/DataExporter";
import VTitle from "v-title";
import Moment from "moment";
import { VMoney } from "v-money";
import { extendMoment } from "moment-range";
import { func } from "@/functions";
const moment = extendMoment(Moment);
Vue.use(VTitle);
Vue.use(VueHead);
export default {
  mixins: [func],
  name: "report-forms",
  directives: {
      money: VMoney,
  },
  components: {
      HotelDatePicker,
  },
  head: {
      title: function () {
          return {
              inner: `TicketOffice | Admin`,
              separator: " | ",
              complement: "Relatórios - Formulário",
          };
      },
  },
  computed: {
      mayIsee() {
          return this.mayI("report-viewer", "report-insurance");
      },
  },
  created() { },
  async mounted() { 
    await Promise.all([this.getEvents(), this.selectionUpdated()]);
   },
  methods: {
      handleSort (sort) {
          this.sortColumn = sort.sortBy;
          this.sortDirection = sort.sortDesc;
          this.selectionUpdated();
      },
      pagto(to) {
          this.grids.default.currentPage = to;
          this.selectionUpdated();
      },
      async getEvents() {
        const response = await eventService.list(this.getLoggedId(), "", 1, 5000);
        this.eventsToShow = response
        .filter(event => event.hasshowyet === 1)
        .map(event => ({ value: event.id_evento, text: event.ds_evento }))
        .sort((a, b) => a.text.localeCompare(b.text));

        // Insert the default option at the beginning
        this.eventsToShow.unshift({ value: null, text: "Selecione um evento" });
      },
      async exportData() {
          if (!this.grids.default.total) return this.toastError("Não há dados para exportar");
          this.showWaitAboveAll();
          const dataExporter = new DataExporter({
              serviceToCall: insuranceService.listForms,
              optionsToService: this.formatOptionsToQuery(),
              itemsPerPage: 10000,
              totalPages: this.grids.default.total,
              columnsToExport: this.exportColumns,
              fileName: "formularios_seguro_autorizacao",
          });
          await dataExporter.exportData();
          this.hideWaitAboveAll();
      },
      formatOptionsToQuery() {
          const options = {
              dateStart: moment(this.components.datepicker.startDate).format(
                  "YYYY-MM-DD"
              ),
              dateEnd: moment(this.components.datepicker.endDate).format(
                  "YYYY-MM-DD"
              ),
              loggedId: this.getLoggedId(),
              currentPage: this.grids.default.currentPage,
              perPage: this.grids.default.perPage,
              sortDirection: this.sortDirection ? "DESC" : "ASC",
              sortColumn: this.sortColumn,
              evento_id: this.eventSelected
          };
          if (!this.components.datepicker.endDate) {
              this.toastError("Selecione uma data final");
              return false
          }
          return options;
      },
      async customDateConfirmed() {
          if (this.customDate.length > 1 && this.customDate[0]) {
              if (moment(this.customDate[0]).isValid()) {
                  this.components.datepicker.startDate = moment(
                      this.customDate[0]
                  ).format("YYYY-MM-DD");
              }
              if (moment(this.customDate[1]).isValid()) {
                  this.components.datepicker.endDate = moment(
                      this.customDate[1]
                  ).format("YYYY-MM-DD");
              }
              await this.selectionUpdated();
          }
      },
      async selectionUpdated() {
          this.generalData = [];
          this.showWaitAboveAll();
          await new Promise((resolve) => setTimeout(resolve, 500));
          const options = this.formatOptionsToQuery();
          if (!options || !options.dateStart) return this.hideWaitAboveAll();
          this.processing = true;
          const response = await insuranceService.listForms(options);
          this.processing = false;
          this.grids.default.processing = false;
          this.hideWaitAboveAll();
          if (this.validateJSON(response)) {
              this.grids.default.loaded = true;
              this.grids.default.items = response;
              this.grids.default.currentPage = response.length == 0 ? 1 : response[0]["currentPage"];
              this.grids.default.total = response.length == 0 ? 0 : response[0]["totalCount"];
          }
      },
  },
  data() {
      return {
          processing: false,
          customDate: [
              new Date(),
              new Date()
          ],
          eventsToShow: [],
          eventSelected: null,
          sortColumn: "_id",
          sortDirection: "DESC",
          exportColumns: {
              "id": "_id",
              "DATA": "createdAt",
              'Evento': 'evento',
              'Base': 'base',
              "Nome": "nome",
              "CPF": "cpf",
              'RG': 'rg',
              'Data de Nascimento': 'aniversario',
              'Ano/Série': 'ano_serie',
              'ID do Pedido': 'pedido_id',
          },
          components: {
              datepicker: {
                  id: 1,
                  format: "DD/MM/YYYY",
                  minNights: 0,
                  maxNights: 0,
                  hoveringTooltip: true,
                  displayClearButton: true,
                  startDate: new Date(),
                  endDate: new Date(),
                  startingDateValue: new Date(),
                  ptBr: {
                      night: "Dia",
                      nights: "Dias",
                      "day-names": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                      "check-in": "Início",
                      "check-out": "Fim",
                      "month-names": [
                          "Janeiro",
                          "Fevereiro",
                          "Março",
                          "Abril",
                          "Maio",
                          "Junho",
                          "Julho",
                          "Agosto",
                          "Setembro",
                          "Outubro",
                          "Novembro",
                          "Dezembro",
                      ],
                  },
              },
          },
          grids: {
              default: {
                  processing: false,
                  loaded: false,
                  total: 0,
                  currentPage: 1,
                  perPage: 20,
                  items: [],
                  fields: {
                      _id: { label: '#id', sortable: false },
                      createdAt: { label: 'DATA', sortable: true },
                      evento: { label: 'Evento', sortable: false },
                      base: { label: 'Base', sortable: false },
                      nome: { label: 'Nome', sortable: false },
                      cpf: { label: 'CPF', sortable: false },
                      rg: { label: 'RG', sortable: false },
                      aniversario: { label: 'Data de Nascimento', sortable: false },
                      ano_serie: { label: 'Ano/Série', sortable: false },
                      pedido_id: { label: 'ID do Pedido', sortable: false },
                  },
              }
          },
      };
  },
};
</script>
<style lang="scss" scoped>
.report-revenues {

  .mx-input:focus,
  .mx-input:focus-visible,
  .mx-input:hover,
  .revenue-selects:focus,
  .revenue-selects:focus-visible {
      outline: none;
      border: 1px solid #ccc !important;
  }

  .mx-input,
  .revenue-selects {
      cursor: pointer;
  }

  .mx-input::placeholder {
      color: #555;
  }

  .item-date-picker-revenue {

      .mx-input-wrapper,
      .mx-input {
          height: 100%;
      }
  }

  .revenue-selects {
      border: 1px solid #ccc;
      color: #555;
      width: 270px;
      height: 34px;
      border-radius: 4px;
  }
}

.report-revenues th {
  background: white;
  position: sticky;
  top: 37px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #504848;
}

.report-revenues .fixed-top-revenue {
  background: white;
  position: sticky;
  top: 0px;
  z-index: 999 !important;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.report-revenues tfoot td {
  background: white;
  position: sticky;
  bottom: 0;
}

.fixedTableLabel {
  max-width: 313px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
</style>